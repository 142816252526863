import React from "react"
import {inject, observer} from "mobx-react";
import Helmet from "react-helmet"

import Layout from "../components/layout"
import colors from '../components/colors';
import impressum_en from '../../content/en_impressum.json'
import impressum_de from '../../content/de_impressum.json'

import ReactMarkdown from "react-markdown"


class Impressum extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
          device: "desktop",
        }
      }
    render() {
    return(
    <Layout>
    <Helmet>
          <title>{'LT | Impressum'}</title>
        </Helmet>
    <div style={{minHeight:'80vh', width:'100%', maxWidth:'980px',margin:'0px auto',color:colors.textLightgrey,backgroundColor: colors.darkblue, padding:'5vw'}}>
    <h1 style={{paddingBottom:'5vh'}}>Impressum</h1>
    {
      this.props.store.language === 'en' ? 
        Object.entries(impressum_en).map((line, index) => {
          return (
            <div key={index}style={{paddingLeft:'3vw'}}>
            <ReactMarkdown escapeHtml={false} parserOptions={{ commonmark: true }} source={line[1]}/>
            </div>
        )})
        :
        Object.entries(impressum_de).map((line, index) => {
          return(
            <div key={index}style={{paddingLeft:'3vw'}}>
                <ReactMarkdown  escapeHtml={false} parserOptions={{ commonmark: true }} source={line[1]}/>
            </div>
        )})
        }
    </div>


    </Layout>
    )}
}
export default inject('store')(observer(Impressum))

